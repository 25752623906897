<template>
  <div class="coachswitch" v-if="isAdmin||isTopManager">
   <div class="buttons radio" v-if="!!this.$store.state.config">
    <button
      :value="this.$store.state.config.team['UZgiD1IEymhtToWu9a3wqZTxv672']"
      v-if="isAdmin"
      @click.prevent="switchCoach('UZgiD1IEymhtToWu9a3wqZTxv672')"
      :disabled="currentCoach=='UZgiD1IEymhtToWu9a3wqZTxv672'"
      :class="currentCoach=='UZgiD1IEymhtToWu9a3wqZTxv672'?'btn gold '+'UZgiD1IEymhtToWu9a3wqZTxv672':'btn '+'UZgiD1IEymhtToWu9a3wqZTxv672'">
        {{this.$store.state.config.team['UZgiD1IEymhtToWu9a3wqZTxv672']  .displayName.split(" ")[0]}}
      </button>
    <button
      v-for="coach, keyid in Coachs"
      v-bind:key="coach.id"
      :value="coach"
      @click.prevent="switchCoach(keyid)"
      :disabled="currentCoach==keyid"
      :class="currentCoach==keyid?'btn gold '+keyid:'btn '+keyid">
        {{coach.displayName.split(" ")[0]}}
      </button>
   </div>
  </div>
</template>

<script>
import {  mapGetters } from "vuex";

  export default {
    name: 'DropDown',
    template: 'DropDown',
    props: ['currentCoach'],
    created() {
      this.$emit('selected', this.selected);
    },
    computed: {
      ...mapGetters(['isUserAuth', 'isAdmin', 'isManager', 'isTopManager',  'getCurrentUser']),
      Coachs() {

        if(this.$store.state.config!=null) {
          /*
          const ordered = Object.keys(this.$store.state.config.team).sort().reduce(
            (obj, key) => {
              obj[key] = this.$store.state.config.team[key];
              return obj;
            },
            {}
          );
          */

          
          const sortable = Object.fromEntries(
              Object.entries(this.$store.state.config.team).sort(([,a],[,b]) => {

                let textA = '';
                if(a.displayName!=null)
                textA = a.displayName.toUpperCase();

                let textB = '';
                if(b.displayName!=null)
                  textB = b.displayName.toUpperCase();

                  console.log(textA, textB);

                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              })
          );

          console.log('sortable', sortable);

          delete sortable['UZgiD1IEymhtToWu9a3wqZTxv672'];

          return sortable;
        } return {};
      },
    },
    methods: {
      switchCoach(id) {
        this.$emit('switchCoach', id);
      }
    },
    watch: {
    }
  };
</script>
