<template>
  <div class="user screen-user">
    <!-- div class="breadcrumbs header">
      <span>-
      <router-link to="/users">Members</router-link> - {{name}}</span>
    </div -->
    <header>
      <h1>
        {{name}}
        <img class="icon" src="@/assets/icons/female.svg" v-if="gender=='f'" /><img class="icon" src="@/assets/icons/male.svg" v-if="gender=='m'" />
        {{age(birthDate)}}
      </h1>
      <div>
      <div v-if="isAdmin||isManager" class="buttons">
      <router-link :to="'/user/'+uid" class="btn" >Retour Fiche</router-link>
      </div></div>
    </header>

    
    <div v-if="isAdmin||isManager">

      <div class='header'>
        <h2 v-if="this.$store.state.userLogs!=null">Historique des {{Object.keys(this.$store.state.userLogs).length}} séances <small><a href="#" @click.prevent="reindexDay">Réindexer</a></small></h2>
        <h2 v-else>Historique des séances <small><a href="#" @click.prevent="reindexDay">Réindexer</a></small></h2>
      </div>

      <div v-if="Object.keys(NextBookings).length>0">
        <div class='header'>
          <h3>Prochain(s) Créneau(x)</h3>
        </div>
        <div>
          <div>
            <template v-for="(booking, keyb) in NextBookings">
              <div v-for="(time, keyt) in booking" v-bind:key="keyt" class="histo">
                <div class="time">
                {{local(keyb)}} à {{keyt}}
                </div>
                  <span v-for="(ccoach,keycoach) in NextBookings[keyb][keyt]" v-bind:key="ccoach.id">
                    <span v-if="time[keycoach] && time[keycoach]['confirmed']">[✔] </span>
                    <span v-else>[&nbsp;] </span>
                    <span v-if="Coachs[keycoach]">{{Coachs[keycoach].displayName}}
                    </span>
                    <span v-if="time[keycoach] && time[keycoach]['group']">(duo)</span>
                  </span>

                </div>
            </template>
          </div>
        </div>
      </div>

      <div class='header'>
        <h2>Historique Solo</h2>
        <span>
          Crédits : {{ credits }}&nbsp;
          <button :class="['btn',solos?'gold':'']" @click="solos=!solos">
            Crédits
          </button>&nbsp;
          <button :class="['btn',soloc?'gold':'']" @click="soloc=!soloc">
            Séances
          </button>
        </span>
      </div>
      <div>
        <div :class="[solos?'':'hnos',soloc?'':'hnoc']">
          <template v-for="(booking, keyb) in sortList(Bookings)">
            <template v-for="(time, keyt) in sortList(booking)" v-bind:key="keyt">
              <div :class="['histo',typeof Bookings[keyb][keyt].action != 'undefined'?'hbooking':'hcredit']" v-if="typeof Bookings[keyb][keyt].action != 'undefined' 
              || typeof Bookings[keyb][keyt][Object.keys(Bookings[keyb][keyt])[0]] != 'undefined' 
              &&  Bookings[keyb][keyt][Object.keys(Bookings[keyb][keyt])[0]].group!=true ">
              <div class="time">
                <router-link v-if="typeof Bookings[keyb][keyt].action!='undefined'" :to="/logs/+ local(keyb).substring( 6, 10) + '-' +  local(keyb).slice( 3, 5) + '-' + local(keyb).slice( 0, 2)+'#'+currentUser.id">
                  {{local(keyb)}}
                </router-link>
                <span v-else>{{local(keyb)}}</span> 
                à {{keyt.slice(0,5)}}
              </div>
              <div v-if="typeof Bookings[keyb][keyt].action!='undefined'" class="credits-line">
                <!-- Credit -->
                {{Bookings[keyb][keyt].action}}
                <span v-if="Bookings[keyb][keyt].action.includes('change')">
                  
                    <span v-if="Bookings[keyb][keyt].change<0">
                    ({{ Bookings[keyb][keyt].change }}) {{ (Bookings[keyb][keyt].credits) }}
                    </span>
                    <span v-else>
                      (+{{ Bookings[keyb][keyt].change }}) {{ (Bookings[keyb][keyt].credits ) }}
                    </span>
                  
                </span>
                <span v-else>
                  <span v-if="Bookings[keyb][keyt].credits!=null">
                  <span v-if="Bookings[keyb][keyt].change<0">
                  ({{ Bookings[keyb][keyt].change }}) {{ (Bookings[keyb][keyt].credits + Bookings[keyb][keyt].change) }}
                  </span>
                  <span v-else>
                    (+{{ Bookings[keyb][keyt].change }}) {{ (Bookings[keyb][keyt].credits + Bookings[keyb][keyt].change) }}
                  </span>
                  </span>
                </span>
              </div>
              <div v-else>
                <!-- Booking -->
                <span v-for="(ccoach,keycoach) in Bookings[keyb][keyt]" v-bind:key="ccoach.id">
                  <span v-if="Coachs[keycoach]">{{Coachs[keycoach].displayName}}</span>
                  <span v-if="Bookings[keyb][keyt][keycoach] && typeof Bookings[keyb][keyt][keycoach].group != 'undefined' && Bookings[keyb][keyt][keycoach].group"> (duo)</span>
                </span>
              </div>
          </div>
        </template>
          </template>
        </div>
      </div>

      <div class='header'>
        <h2>Historique Duo</h2>
        <span>
          Crédits : {{ creditsD }}&nbsp;
          <button :class="['btn',duos?'gold':'']" @click="duos=!duos">Crédits</button>&nbsp;
          <button :class="['btn',duoc?'gold':'']" @click="duoc=!duoc">Séances</button>
        </span>
      </div>
      
      <div>
        <div :class="[duos?'':'hnos',duoc?'':'hnoc']">
          <template v-for="(booking, keyb) in sortList(BookingsD)">
            <template v-for="(time, keyt) in sortList(booking)" v-bind:key="keyt">
              <div :class="['histo',typeof BookingsD[keyb][keyt].action != 'undefined'?'hbooking':'hcredit']" v-if="BookingsD[keyb][keyt]!=null
              && typeof BookingsD[keyb][keyt].action != 'undefined' 
              || (BookingsD[keyb][keyt]!=null && typeof BookingsD[keyb][keyt][Object.keys(BookingsD[keyb][keyt])[0]] != 'undefined' 
              &&  BookingsD[keyb][keyt][Object.keys(BookingsD[keyb][keyt])[0]].group==true) ">
              
              <div class="time">
                <router-link v-if="typeof BookingsD[keyb][keyt].action!='undefined'" :to="/logs/+ local(keyb).substring( 6, 10) + '-' +  local(keyb).slice( 3, 5) + '-' + local(keyb).slice( 0, 2)+'#'+currentUser.id">
                  {{local(keyb)}}
                </router-link>
                <span v-else>{{local(keyb)}}</span> à {{keyt.slice(0,5)}}
              </div>
              <div v-if="BookingsD[keyb][keyt]!=null && typeof BookingsD[keyb][keyt].action!='undefined'" class="credits-line">
                <!-- Credit -->
                {{BookingsD[keyb][keyt].action}}
                <span v-if="BookingsD[keyb][keyt].action.includes('change')">
                  <span v-if="BookingsD[keyb][keyt].change<0">
                  ({{ BookingsD[keyb][keyt].change }}) {{ BookingsD[keyb][keyt].credits }}
                  </span>
                  <span v-else>
                    (+{{ BookingsD[keyb][keyt].change }}) {{ BookingsD[keyb][keyt].credits }}
                  </span>
                </span>
                <span v-else>
                  <span v-if="BookingsD[keyb][keyt].change<0">
                  ({{ BookingsD[keyb][keyt].change }}) {{ (BookingsD[keyb][keyt].credits + BookingsD[keyb][keyt].change) }}
                  </span>
                  <span v-else>
                    (+{{ BookingsD[keyb][keyt].change }}) {{ (BookingsD[keyb][keyt].credits + BookingsD[keyb][keyt].change) }}
                  </span>
                </span>
              </div>
              <div v-else>
                <!-- Booking -->
                <span v-for="(ccoach,keycoach) in BookingsD[keyb][keyt]" v-bind:key="ccoach.id">
                  <span v-if="Coachs[keycoach]">{{Coachs[keycoach].displayName}}</span>
                  <span v-if="BookingsD[keyb][keyt][keycoach] && typeof BookingsD[keyb][keyt][keycoach].group != 'undefined' && BookingsD[keyb][keyt][keycoach].group"> (duo)</span>
                </span>
              </div>
          </div>
          </template>
          </template>
        </div>
      </div>

      
      <div class='header'>
        <h2>Ancien Historique </h2>
      </div>

      <div>
        <div>
          <template v-for="(booking, keyb) in sortList(PastBookings)">
            <template v-for="(time, keyt) in sortList(booking)" v-bind:key="keyt">
              <div class="histo">
                <div class="time">{{local(keyb)}} 
                à {{keyt.slice(0,5).padStart(5,0)}}</div>
                <div v-if="time>0">+{{ time }}</div>
                <div v-else>{{ time }}</div>
                
              </div>
              
            
            </template>
          </template>
        </div>
      </div>

    </div>

    </div>
</template>
<script>
import firebase from "firebase/compat/app";
import { mapActions, mapGetters } from "vuex";
/* import 'vue-slider-component/theme/antd.css'; */ /* https://nightcatsama.github.io/vue-slider-component/#/ */

export default {
  props: ["user"],
  components: {
  },
  data() {
    return {
      graphs: {
      weight: { name: "Poids", icon: "weighing-scale_", id: "w", min:40,max: 160 },
        bodyfat: { name: "Masse grasse", icon: "trans-fat_", id: "bf", min:0, max: 50 },
        bodywater: { name: "Volume hydrique", icon: "drops_", id: "bw", min:0, max: 50 },
        musclemass: { name: "Masse sèche", icon: "muscle_", id: "mm", min:0, max: 50 }
      },
      gstats: [
        { label: "A", value: 60 },
        { label: "B", value: 60 },
        { label: "C", value: 60 },
        { label: "D", value: 60 }
      ],
      solos: false,
      soloc: true,
      duos: false,
      duoc: true,
      currentGraph: 'weight',
      currentUser: null,
      isEditing: false,
      name: '',
      email: '',
      phone: '',
      gender: '',
      height: '',
      userBookings: {},
      credits: 0,
      creditsD: 0,
      birthDate: '',
      lastLoginAt: '',
      createdAt: '',
      stats: [],
      validationErrors: [],
      uid: null,
      valueSelected: null,
      data: ['a', 'b', 'c', 'd', 'e', 'f', 'g'],
      options: {
        dotSize: 14,
        width: 'auto',
        height: 4,
        contained: false,
        direction: 'ltr',
        dataLabel: 'label',
        dataValue: 'value',
        min: 0,
        max: 100,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: 'none',
        tooltipPlacement: 'top',
        tooltipFormatter: void 0,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: true,
        order: true,
        marks: true,
        dotOptions: void 0,
        dotAttrs: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      }
    };
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'isUserAuth', 'isAdmin', 'isManager', 'getServerTime']),
    Credits() {
      let credits = {};
      if(this.SoloCredits!=null ||  this.DuoCredits != null)
        credits = Object.assign(this.SoloCredits,this.DuoCredits);
      return credits;
    },
    SoloCredits() {
//      let solocredits = this.$store.state.lookedAtCreditsHistory.solo;

      let ordered = null;
      if(this.$store.state.lookedAtCreditsHistory!=null)
        if(typeof this.$store.state.lookedAtCreditsHistory.solo!="undefined"  && this.$store.state.lookedAtCreditsHistory.solo!=null)
          ordered = Object.keys(this.$store.state.lookedAtCreditsHistory.solo).sort().reverse().reduce(
          (obj, key) => {
            obj[key] = this.$store.state.lookedAtCreditsHistory.solo[key];
            return obj;
          },
          {}
        );
        
      return ordered; 
    },
    DuoCredits() {
      //let duocredits = this.$store.state.lookedAtCreditsHistory.duo
      
      let ordered = null;
      if(this.$store.state.lookedAtCreditsHistory!=null)
        if(typeof this.$store.state.lookedAtCreditsHistory.duo!="undefined"  && this.$store.state.lookedAtCreditsHistory.duo!=null)
          ordered = Object.keys(this.$store.state.lookedAtCreditsHistory.duo).sort().reverse().reduce(
          (obj, key) => {
            obj[key] = this.$store.state.lookedAtCreditsHistory.duo[key];
            return obj;
          },
          {}
        );
        
      return ordered; 

      //return duocredits;
    },
    NextBookings() {

      const trainings = [];

      if(this.$store.state.lookedAtBookings==null) {
        return trainings;
      } else {


        let ordered1 = null;
        if(typeof this.$store.state.lookedAtBookings!="undefined" )
        ordered1 = Object.keys(this.$store.state.lookedAtBookings).sort().reverse().reduce(
          (obj, key) => {
            obj[key] = this.$store.state.lookedAtBookings[key];
            return obj;
          },
          {}
        );

        let ordered = Object.assign({},ordered1);

        let date = null;
        const today = new Date();

        today.setHours(0,0,0,0);

        const ordered3 = Object.keys(ordered).sort().reverse().reduce(
          (obj, key) => {
            date = new Date(key);
            date.setHours(0,0,0,0);


            if(typeof key != "undefined") {
            if(date >= today)
              obj[key] = ordered[key];
            }
            return obj;

          },
          {}
        );


        let final = {};
        final = JSON.parse( JSON.stringify( ordered3 ) );
        return final;
      }

    },

    PastBookings() {

      const trainings = [];

      if(this.$store.state.pastUserLogs==null) {
        return trainings;
      } else {


        let ordered1 = null;
        
        if(typeof this.$store.state.pastUserLogs!="undefined" ) {
          for(let booking in this.$store.state.pastUserLogs) {
            let bookingdata = false;
            bookingdata = this.$store.state.pastUserLogs[booking][Object.keys(this.$store.state.pastUserLogs[booking])[0]][Object.keys(this.$store.state.pastUserLogs[booking][Object.keys(this.$store.state.pastUserLogs[booking])[0]])[0]];
            if(typeof bookingdata != "undefined" && bookingdata.user != this.uid && bookingdata.user2 != this.uid)
              delete this.$store.state.pastUserLogs[booking];
            
          }
          ordered1 = Object.keys(this.$store.state.pastUserLogs).sort().reverse().reduce(
          (obj, key) => {
            obj[key] = this.$store.state.pastUserLogs[key];
            return obj;
          },
          {}
        );
        }
        let ordered2 = null;
        
        /*
        
        if(typeof this.SoloCredits!="undefined" && this.SoloCredits!=null)
         ordered2 = Object.keys(this.SoloCredits).sort().reverse().reduce(
          (obj, key) => {
            obj[key] = this.SoloCredits[key];
            return obj;
          },
          {}
        );
        
        */

        for (let rdv in ordered1) {
          if(typeof ordered2!="undefined"  && ordered2!=null && typeof ordered2[rdv]!="undefined" && ordered2[rdv]!=null) {
            let result = Object.assign({},ordered1[rdv], ordered2[rdv]);
              result = Object.keys(result).sort().reverse().reduce( (obj, key) => { obj[key] = result[key]; return obj; }, {});
            ordered1[rdv] = result;
            ordered2[rdv] = result;
          }
        }

        let ordered = Object.assign({},ordered1 , ordered2 );

        let date = null;
        const today = this.getServerTime;
        today.setHours(0,0,0,0);

        const ordered3 = Object.keys(ordered).sort().reverse().reduce(
          (obj, key) => {
            date = new Date(key);
            date.setHours(0,0,0,0);
            obj[key] = ordered[key];
            return obj;

          },
          {}
        );

        let final = {};
        final = JSON.parse( JSON.stringify( ordered3 ) );
        console.log('final');

        return final;
      }

      },
    /*
    Credits() {
      const trainings = [];
      if(this.$store.state.lookedAtCredits==null) {
        return trainings;
      } else {
        let ordered = null;
        if(typeof this.$store.state.lookedAtCredits!="undefined"  && this.$store.state.lookedAtCredits!=null)
         ordered = Object.keys(this.$store.state.lookedAtCredits).sort().reverse().reduce(
          (obj, key) => {
            obj[key] = this.$store.state.lookedAtCredits[key];
            return obj;
          },
          {}
        );

        return ordered;
      }
    }, */



    Bookings() {

      const trainings = [];

      if(this.$store.state.lookedAtCreditsHistory==null) {
        return trainings;
      } else {


        let ordered1 = null;
        
        if(typeof this.$store.state.userLogs!="undefined" && this.$store.state.userLogs!=null) {
          for(let booking in this.$store.state.userLogs) {
            let bookingdata = false;
            bookingdata = this.$store.state.userLogs[booking][Object.keys(this.$store.state.userLogs[booking])[0]][Object.keys(this.$store.state.userLogs[booking][Object.keys(this.$store.state.userLogs[booking])[0]])[0]];
            if(typeof bookingdata != "undefined" && bookingdata.user != this.uid && bookingdata.user2 != this.uid)
              delete this.$store.state.userLogs[booking];
            
          }
          ordered1 = Object.keys(this.$store.state.userLogs).sort().reverse().reduce(
          (obj, key) => {
            obj[key] = this.$store.state.userLogs[key];
            return obj;
          },
          {}
        );
        }
        

        let ordered2 = null;
        
        if(typeof this.SoloCredits!="undefined" && this.SoloCredits!=null)
         ordered2 = Object.keys(this.SoloCredits).sort().reverse().reduce(
          (obj, key) => {
            obj[key] = this.SoloCredits[key];
            return obj;
          },
          {}
        );
        

        for (let rdv in ordered1) {
          if(typeof ordered2!="undefined"  && ordered2!=null && typeof ordered2[rdv]!="undefined" && ordered2[rdv]!=null) {
            let result = Object.assign({},ordered1[rdv], ordered2[rdv]);
              result = Object.keys(result).sort().reverse().reduce( (obj, key) => { obj[key] = result[key]; return obj; }, {});
            ordered1[rdv] = result;
            ordered2[rdv] = result;
          }
        }

        let ordered = Object.assign({},ordered1 , ordered2 );

        let date = null;
        const today = this.getServerTime;
        today.setHours(0,0,0,0);

        const ordered3 = Object.keys(ordered).sort().reverse().reduce(
          (obj, key) => {
            date = new Date(key);
            date.setHours(0,0,0,0);
            obj[key] = ordered[key];
            return obj;

          },
          {}
        );

        let final = {};
        final = JSON.parse( JSON.stringify( ordered3 ) );
        console.log('final');

        return final;
      }

    },

    
    BookingsD() {

      const trainings = [];

      if(this.$store.state.lookedAtCreditsHistory==null) {
        return trainings;
      } else {


        let ordered1 = null;
        
        if(typeof this.$store.state.userLogs!="undefined" && this.$store.state.userLogs!=null) {
          for(let booking in this.$store.state.userLogs) {
            let bookingdata = false;
            bookingdata = this.$store.state.userLogs[booking][Object.keys(this.$store.state.userLogs[booking])[0]][Object.keys(this.$store.state.userLogs[booking][Object.keys(this.$store.state.userLogs[booking])[0]])[0]];
            if(typeof bookingdata != "undefined" && bookingdata.user != this.uid && bookingdata.user2 != this.uid)
              delete this.$store.state.userLogs[booking];
            
          }

          ordered1 = Object.keys(this.$store.state.userLogs).sort().reverse().reduce(
          (obj, key) => {
            obj[key] = this.$store.state.userLogs[key];
            return obj;
          },
          {}
        );
        }
        

        let ordered2 = null;
        
        if(typeof this.DuoCredits!="undefined" && this.DuoCredits!=null)
        ordered2 = Object.keys(this.DuoCredits).sort().reverse().reduce(
          (obj, key) => {
            obj[key] = this.DuoCredits[key];
            return obj;
          },
          {}
        );
        

        for (let rdv in ordered1) {
          if(typeof ordered2!="undefined"  && ordered2!=null && typeof ordered2[rdv]!="undefined" && ordered2[rdv]!=null) {
            let result = Object.assign({},ordered1[rdv], ordered2[rdv]);
              result = Object.keys(result).sort().reverse().reduce( (obj, key) => { obj[key] = result[key]; return obj; }, {});
            ordered1[rdv] = result;
            ordered2[rdv] = result;
          }
        }

        let ordered = Object.assign({},ordered1 , ordered2 );

        let date = null;
        const today = this.getServerTime;
        today.setHours(0,0,0,0);

        const ordered3 = Object.keys(ordered).sort().reverse().reduce(
          (obj, key) => {
            date = new Date(key);
            date.setHours(0,0,0,0);
            obj[key] = ordered[key];
            return obj;

          },
          {}
        );

        let final = {};
        

        final = JSON.parse( JSON.stringify( ordered3 ) );

        return final;
      }

      },

    Trainings() {
      const trainings = [];
      if(this.$store.state.userTrainings==null) {
        return trainings;
      } else {
      const ordered = Object.keys(this.$store.state.userTrainings).sort().reverse().reduce(
        (obj, key) => {
          obj[key] = this.$store.state.userTrainings[key];
          return obj;
        },
        {}
      );

      let arr = {};
      if(Object.keys(ordered).length>0) {
      arr[Object.keys(ordered)[0]] = ordered[Object.keys(ordered)[0]];
      /* arr[Object.keys(ordered)[1]] = ordered[Object.keys(ordered)[1]]; */
      }


      return arr;
      }
    },

    Results() {

      let chart = {};

      for(let equipment in this.Equipments) {
        chart[equipment] = {};
        for (let training in this.Trainings)
          if(typeof this.Trainings[training][equipment] != "undefined")
            chart[equipment][training] = this.Trainings[training][equipment];
      }

      let check = false;
      for(let eq in chart) {
        for(let training in this.Trainings) {
         if(typeof this.Trainings[training][eq] != "undefined") {
          check = true;
         }
        }

        if(!check) delete chart[eq];
      }


      const ordered = Object.keys(chart).sort().reduce(
        (obj, key) => {
          obj[key] = chart[key];
          return obj;
        },
        {}
      );

      return ordered;

    },

    value :  {
        get: function () {
          if(this.valueSelected==null) {
            const measurements = [];
            if(this.$store.state.userMeasurements==null) {
              return measurements;
            } else {
            const ordered = Object.keys(this.$store.state.userMeasurements).sort().reduce(
              (obj, key) => {
                obj[key] = this.$store.state.userMeasurements[key];
                return obj;
              },
              {}
            );
            let sliceLength = 3;
            if(Object.keys(ordered).length>5) sliceLength = 5;
            return Object.keys(ordered).slice(-sliceLength);
            }
          } else return this.valueSelected;
        },
        // setter
        set: function (newValue) {
          this.valueSelected = newValue;
        }
    },
    Measurements() {
      const measurements = [];
      if(this.$store.state.userMeasurements==null) {
        return measurements;
      } else {
      const ordered = Object.keys(this.$store.state.userMeasurements).sort().reduce(
        (obj, key) => {
          obj[key] = this.$store.state.userMeasurements[key];
          return obj;
        },
        {}
      );

      return ordered;
      }
    },

    Coachs() {


      if(this.$store.state.config!=null) {
        const ordered = Object.keys(this.$store.state.config.team).sort().reduce(
          (obj, key) => {
            obj[key] = this.$store.state.config.team[key];
            return obj;
          },
          {}
        );
          
        const ordered2 = Object.keys(this.$store.state.config.teamArchive).sort().reduce(
          (obj, key) => {
            obj[key] = this.$store.state.config.teamArchive[key];
            return obj;
          },
          {}
        );
        

        return Object.assign(ordered,ordered2);

      } return {};

    },
    SelectedMeasurements() {
      let Selected = JSON.parse(JSON.stringify(this.Measurements));
      for(let measurement in Selected) {
        if(!this.value.includes(measurement))
          delete Selected[measurement];
      }
      return Selected;
    },
    Total() {

      return true;
      /*

      let total = 0;
      let moy = 0;
      let max = 0;

      if(this.$store.state.userTrainings==null) {
        return 0;
      } else {

      Object.keys(this.$store.state.userTrainings).forEach(key => {

        if(typeof this.$store.state.userTrainings[key].c !="undefined")
          total = total + parseInt(this.$store.state.userTrainings[key].c)

        if(typeof this.$store.state.userTrainings[key].fc.moy !="undefined")
          if(moy!=0)
            moy = (moy + parseInt(this.$store.state.userTrainings[key].fc.moy))/2;
          else
            moy = parseInt(this.$store.state.userTrainings[key].fc.moy);

        if(typeof this.$store.state.userTrainings[key].fc.max !="undefined")
          if(parseInt(max)<parseInt(this.$store.state.userTrainings[key].fc.max))
            max = parseInt(his.$store.state.userTrainings[key].fc.max)

      });
      return {total: total, moy: moy, max:max};
      }
      */
    }
  },
  
  methods: {
    
  sortList(list) {
      let ordered = null;
        if(typeof list!="undefined"  && list!=null)
          ordered = Object.keys(list).sort().reverse().reduce(
          (obj, key) => {
            obj[key] = list[key];
            return obj;
          },
          {}
        );
        
      return ordered; 
    },
    minMax() {

      let check = false;


      for(let graph in this.graphs) {
        let min = 9999;
        let max = 0;
        if(typeof this.Measurements != "undefined")
        for(let measurement in this.Measurements) {
          if(min>parseInt(this.Measurements[measurement][this.graphs[graph].id])) min = parseInt(this.Measurements[measurement][this.graphs[graph].id]);
          if(max<parseInt(this.Measurements[measurement][this.graphs[graph].id])) max = parseInt(this.Measurements[measurement][this.graphs[graph].id]);
          check = true;
        }
        this.graphs[graph].min = parseInt(min) - 1;
        this.graphs[graph].max = parseInt(max) + 1;
      }

      return check;

    },


      getIconUrl(icn) {
        var images = require.context('../assets/icons/', false, /\.svg$/)
        return images('./' + icn + ".svg")
      },
    local(day) {
      const d = new Date(day+'T12:00:00');
      const options = { month: 'numeric', day: 'numeric', year: 'numeric' };

      return d.toLocaleDateString('fr-FR', options);
    },
    removeUser() {
      console.log('remove');
    },

    saveCreditB() {
      const data = {};
      data.user = this.uid;
      data.credits = this.credits;
      this.saveCredit(data)
      this.isEditing = false;
    },

    editUser() {
      this.isEditing = true;
    },
    updateCredits() {
      this.isEditing = false;
      console.log('update');
    },
    updateUser() {
      this.isEditing = false;
      console.log('update');
    },
   resetError() {
     this.validationErrors = [];
   },
   age(sec) {
     var ageDifMs = Date.now() - new Date(sec);
     var ageDate = new Date(ageDifMs); // miliseconds from epoch
     return Math.abs(ageDate.getUTCFullYear() - 1970);
   },
   
   reindexDay() {
      this.$store.dispatch("reindexUserBookings", {day:'2021-01-01', user: this.uid});
    },  
   polyline() {
     const container = [];

     for(let graph in this.graphs) {
       const poly = [];
       let i = 0;
       poly.push('50,400');
       if(typeof this.SelectedMeasurements != "undefined")
       for(let measurement in this.SelectedMeasurements) {

         const x = 50 + 640/(Object.keys(this.SelectedMeasurements).length-1) * i++
         const y = 400 - (400/(this.graphs[graph].max - this.graphs[graph].min) ) * (parseFloat(this.SelectedMeasurements[measurement][this.graphs[graph].id]) - this.graphs[graph].min)
         poly.push(x + "," + y);

       }
       poly.push('690,400');

       container[this.graphs[graph].id] = poly;
     }

     return container;
   },
  validate() {
    // Clear the errors befo$re we validate again
    this.resetError();

    /*
    // email validation
    if (!this.email) {
      this.validationErrors.push("<strong>E-mail</strong> cannot be empty.");
    }
    if (/.+@.+/.test(this.email) != true) {
      this.validationErrors.push("<strong>E-mail</strong> must be valid.");
    }
    // password validation

    if (!this.password) {
      this.validationErrors.push("<strong>Password</strong> cannot be empty");
    }
    if (/.{6,}/.test(this.password) != true) {
      this.validationErrors.push(
        "<strong>Password</strong> must be at least 6 characters long"
      );
    }

    if (!(this.password === this.passwordRepeat)) {
      this.validationErrors.push("<strong>Passwords</strong> did not match");
    }
    */

    // when valid then sign in
    if (this.validationErrors.length <= 0) {

        const user = firebase.auth().currentUser;
        const dateTime = new Date(this.birthDate).getTime();

        user.updateProfile({
          displayName: this.name,
        }).then(() => {
          console.log("Update successful.")
        }
        ).catch(() => {
            console.log("An error happened.")
        });

        const upUser = {};
        upUser.uid = user.uid
        upUser.displayName = user.displayName
        upUser.gender = this.gender
        upUser.height = this.height
        upUser.credits = this.credits
        upUser.birthDate = {seconds : dateTime/1000, nanoseconds: 0}
        upUser.stats = this.stats
        this.$store.dispatch("updateUser", upUser);
/*
        gender: this.gender,
        height: this.height,
        birthDate: dateTime
*/
      }
    },
    ...mapActions([
      'saveCredit'
    ]),
  },
  updated() {
    if(this.currentUser == null && this.$store.state.lookedAtUser !=null) {
    const currentUser = this.$store.state.lookedAtUser;
    this.currentUser = currentUser;
    if(currentUser != null) {
      this.name = currentUser.displayName,
      this.email = currentUser.email;
      this.phone = currentUser.phoneNumber;
      if(typeof currentUser.birthDate != "undefined") {
        this.gender = currentUser.gender;
        this.credits = currentUser.credits;
        if(typeof currentUser.creditsD != 'undefined' && this.creditsD!= null) 
          this.creditsD = currentUser.creditsD;
        else
          this.creditsD = 0;
        this.height = currentUser.height;
        const parsedTimestamp = new Date(currentUser.birthDate.seconds * 1000);
        const month = parsedTimestamp.getMonth()+1;
        const date = parsedTimestamp.getDate();
        this.birthDate =   parsedTimestamp.getFullYear()+ '-' + month.toString().padStart(2,0) + '-' + date.toString().padStart(2, '0');
        this.stats = currentUser.stats;
      }
    }
    }
  },
  mounted() {

    if(this.currentUser == null && this.$store.state.lookedAtUser !=null) {
    const currentUser = this.$store.state.lookedAtUser;
    this.currentUser = currentUser;
    if(currentUser != null) {
      this.name = currentUser.displayName,
      this.email = currentUser.email;
      this.phone = currentUser.phoneNumber;
      if(typeof currentUser.birthDate != "undefined") {
        this.userBookings = currentUser.bookings;
        this.gender = currentUser.gender;
        this.credits = currentUser.credits;
        if(typeof currentUser.creditsD != 'undefined' && this.creditsD!= null) 
          this.creditsD = currentUser.creditsD;
        else
          this.creditsD = 0;
        this.height = currentUser.height;
        const parsedTimestamp = new Date(currentUser.birthDate.seconds * 1000);
        const month = parsedTimestamp.getMonth()+1;
        const date = parsedTimestamp.getDate();
        this.birthDate =   parsedTimestamp.getFullYear()+ '-' + month.toString().padStart(2,0) + '-' + date.toString().padStart(2, '0');
        this.stats = currentUser.stats;
      }
    }
    }

    setTimeout(()=>{
      this.minMax();
    }, 400);

  },
  created() {
    if(typeof this.user == "undefined")
      this.uid = this.$route.params.uid;
    else
      this.uid = this.user;

    this.$store.dispatch("bindUser", {user : this.uid});
    this.$store.dispatch("bindConfig");
    this.$store.dispatch("bindUserBookings", {user : this.uid});
    this.$store.dispatch("bindUserHistory", {user : this.uid});
    this.$store.dispatch("bindPastUserLogs", {user : this.uid});
    this.$store.dispatch("bindUserCredits", {user : this.uid});
    this.$store.dispatch("bindUserCreditsHistory", {user : this.uid});
    this.$store.dispatch("bindMeasurements", {user : this.uid});
    this.$store.dispatch("bindTrainings", {user : this.uid});
    this.$store.dispatch("bindEquipments");

  }
};
</script>
