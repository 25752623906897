<template>
  <div :class="isMounted?'appscreen screen-login  fullscreen mounted':'appscreen screen-login fullscreen'" v-if="isAdmin || isManager">
    <!-- div class="screen" -->
    
  <header>
    <h1><router-link :to="'/users'">Membres</router-link></h1>
  </header>


  <div>
  <!-- div class="field checkbox">
      <label class="label">Prix Achat Verres HT</label>
      <div class="control">
        <input type="number" step=".01" v-model="lensCost" >
      </div>
    </div -->

    <!-- Number Input Form -->
    <div id="recaptcha-container"></div>
    <div class="form-container" id="sign-in-container" v-if="showNumberInput">
      <form v-on:submit.prevent="onSignInSubmit()">
        <div class="field">
          <label class="label">Numéro de téléphone</label>
          <!-- input type="email" v-model="email" required />
          <input type="text" class="" v-model="numberInputForm.number" @keyup="isPhoneNumberValid" placeholder="Numéro de téléphone" required autocomplete="off" -->
          <input type="tel" class="" v-model="numberInputForm.number" @keyup="isPhoneNumberValid" placeholder="Numéro de téléphone" required autocomplete="off">
        </div>
        <div class="field submit">
          <button type="submit" id="get-sign-in-code" class="btn button success theme-accent" disabled>{{ getSignInCodeButton.text }}</button>
        </div>
      </form>
    </div>

    <!-- SMS Verification Form -->
    <div class="form-container" id="sign-in-code-container" v-if="showCodeInput">
      <form v-on:submit.prevent="onVerifyCodeSubmit()">
        <div class="field">
          <label class="label">Code de vérification</label>
          <input type="tel" class="" v-model="numberInputForm.code" placeholder="Code de vérification" required>
      </div>
      <div class="field submit">
          <button type="submit" id="send-verify-code" class="btn button success theme-accent">{{ signInButton.text  }}</button>
        </div>
      </form>
    </div>

    <div v-if="showNewUser" class="profile-signin">
      <Profile :user="user.uid" :phonen="user.phoneNumber" @updated="showNewUser=false" v-if="user" />
    </div>

   </div>
  </div>
</template>

<script>
// https://github.com/firebase/quickstart-js/blob/master/auth/phone-invisible.html
// https://stackoverflow.com/questions/48322164/firebase-phone-number-authentication-with-vue-js-does-not-work
// https://stackoverflow.com/questions/51285008/firebase-recaptchaverifier-clear-has-no-effect
/* import Vue from 'vue'; */
import firebase from 'firebase/compat/app'; 
import { secondaryApp } from '../db'
import 'firebase/compat/auth';
import { PhoneAuthProvider } from 'firebase/auth';
import Profile from "../components/UserProfile.vue";
import { /* mapActions,*/ mapGetters } from "vuex";

export default {
  name: 'NewAccount',
  components: {
    Profile
  },
  data() {
    return {
      // UI States
      showNumberInput: true,
      showCodeInput: false,
      showNewUser: false,
      user: null,
      email: null,
      isMounted: false,
      filter: null,

      // Forms
      numberInputForm: {
        number: '',
        code: ''
      },

      // Buttons
      getSignInCodeButton: {
        text: 'Envoyer le code',
      },
      signInButton: {
        text: 'Créer le compte',
      },
    };
  },

  computed: {
    ...mapGetters(['isAdmin', 'isManager', 'getServerTime']),
  },

  mounted() {

  secondaryApp.auth().languageCode = 'fr';

  //document.querySelector('.form-group input').focus()

    // Start Firebase invisible reCAPTCHA verifier
    console.log('mounted');
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': function() {
      return new Promise( (resolve) => {

       console.log('recaptcha OK')

       //Instead of using 'return false', use reject()
       //Instead of using 'return' / 'return true', use resolve()
       resolve();

     }); //end promise
      }
    });

  },

  methods: {
    /**
     * Sends the user an SMS-verification code using Firebase auth
     *
     * @see https://firebase.google.com/docs/auth/web/phone-auth
     */

    isPhoneNumberValid() {
     var pattern = /^[0-9()-.\s]+$/;
     var phoneNumber = this.numberInputForm.number
     this.numberInputForm.number = phoneNumber.replace(/[^0-9+]/g, '').replace(/(\..*)\./g, '$1');
     let check = false;
     check = (phoneNumber.search(pattern) !== -1);
     if(!phoneNumber.startsWith("+") && !phoneNumber.startsWith("0")) check = false
     if(phoneNumber.length != 10 && phoneNumber.length != 13 && phoneNumber.length != 12) check = false
     if(check){
      if(phoneNumber.startsWith("0")) this.numberInputForm.number= '+33' + this.numberInputForm.number.substring(1)
      document.querySelector('#get-sign-in-code').disabled = false;
      }
     else
      document.querySelector('#get-sign-in-code').disabled = true;

     return check;
    },

    onSignInSubmit() {

      if (this.isPhoneNumberValid()) {
      // window.signingIn = true;
      // updateSignInButtonUI();


      this.getSignInCodeButton = {
        showSpinner: true,
        text: 'Envoi du SMS..',
        disabled: true,
      };

      var phoneNumber = this.numberInputForm.number
      var appVerifier = window.recaptchaVerifier;

      
      secondaryApp.auth().signInWithPhoneNumber(phoneNumber , appVerifier)
          .then( (confirmationResult) => {
            window.confirmationResult = confirmationResult;
            window.signingIn = false;
            this.showCodeInput = true;
            this.getSignInCodeButton = {
              showSpinner: false,
              text: 'SMS envoyé',
              disabled: true,
            };
            console.log('sent', confirmationResult)
            document.querySelector('#get-sign-in-code').classList.add('disabled')
            document.querySelector('#sign-in-container').classList.add('hide')

          }).catch(function (error) {
            // Error; SMS not sent

            alert('Erreur "' + error.code + '"\n\n');
            document.querySelector('#get-sign-in-code').innerHTML = 'Obtenir le code';
            console.log('Error during signInWithPhoneNumber:\n\n'
                + error.code + '\n\n' + error.message);
            window.signingIn = false;
            // updateSignInFormUI();
            // updateSignInButtonUI();
          });
          
        }
      },
      onVerifyCodeSubmit () {

        
        if (this.numberInputForm.code!='') {
          window.verifyingCode = true;
          // updateVerifyCodeButtonUI();
          var code = this.numberInputForm.code;
          var credential = PhoneAuthProvider.credential(window.confirmationResult.verificationId, code);
          
         this.$store.dispatch("createUser", {credential: credential});

          /*
          window.confirmationResult.confirm(code).then( (result) => {
            // User signed in successfully.
            var user = result.user;
            this.user = user;
            console.log(this.user)
            window.verifyingCode = false;
            window.confirmationResult = null;
            this.showNumberInput = false;
            this.showCodeInput = false;
            this.showNewUser = true;
            // updateVerificationCodeFormUI();
          }).catch(function (error) {
            // User couldn't sign in (bad verification code?)
            console.error('Error while checking the verification code', error);
            window.alert('Error while checking the verification code:\n\n'
                + error.code + '\n\n' + error.message);
            window.verifyingCode = false;
            // updateSignInButtonUI();
            // updateVerifyCodeButtonUI();
          });
          */
        }

      }

  },
};

</script>
