import { db, messaging } from '../db'
//import firebase from 'firebase/compat/app';
import { getToken, onMessage} from "firebase/messaging";

const axios = require('axios');

const VAPID_KEY = "BB8sPQGo3cWoE5Z9HyFI2qTh9H1n0kuJxWFLgtvVRO9RG5lsX3UXM9i_YMlx-7OkXoORvhamSoLnkgmkd1xAlBs"; 
const FCM_TOKEN_COLLECTION = "fcmTokens";

export async function requestNotificationsPermissions(uid) {
    const permission = await Notification.requestPermission();

    if(permission==="granted") {
        await saveMessagingDeviceToken(uid);
    } else {
        console.log('No permission');
    }
}

export async function saveMessagingDeviceToken(uid){
    
    const msg = await messaging;
    const fcmToken = await getToken(messaging, {vapidKey: VAPID_KEY});

    if(fcmToken) {
        console.log('Got fcm token: ', fcmToken);

        await db.collection(FCM_TOKEN_COLLECTION).doc(uid).set({fcmToken});

        onMessage(msg, (message) => {
            console.log('Nouvelle notification ', message.notification, message.data);
            new Notification(message.notification.title, {body: message.notification.body});
        });
    } else {
        requestNotificationsPermissions(uid);
    }
}


export async function sendMessaginMessage(message){

    console.log('sendMessage', message);
    const ACCESS_TOKEN = "ya29.a0AeXRPp6ZWZKsAmuXbwX4qogwIUGPWNp0rcZRVcqe2met5bc3Ng5M9vbQt6cqqW5jgl38c628zN6D12MFdFktFQj_V2laloCmklGYvQbXKCAFr_L5bqce158cXxGrJ2lx0oSLrmo16Q7TEIAEmIr58h96YPB8RvldUSbIL60waCgYKARgSARASFQHGX2MiJm2FG3GNpdHe1HczxFkVKg0175";
    axios.post('https://fcm.googleapis.com/v1/projects/l-endroit-4c75b/messages:send' , {
        "message": {
          "token": message.token,
          "data": {
            "uid" : message.uid
          },
          "notification": {
            "title": message.notification.title,
            "body": message.notification.body
          } /*,
          "webpush": {
            "headers": {
              "Urgency": "high"
            },
            "notification": {
              "body": message.notification.body,
              "requireInteraction": "true",
            }
          }*/
        }
      }, {
        headers: {
            'Authorization': 'Bearer '+ACCESS_TOKEN,
            'Content-Type': 'application/json',
        }
        })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    /*
    const test = getMessaging();
    console.log(test, message);
    test.send(message).then((response) => {
      // Response is a message ID string.
      console.log('Successfully sent message:', response);
    })
    .catch((error) => {
      console.log('Error sending message:', error);
    });*/

}

export default messaging