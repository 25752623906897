<template>
  <div class="day">

    <div :class="current?'current-day labelday':'labelday'" >
    <div v-if="!Bookings.length && isLoading" class="spinner labelday"><div><img class="icon" src="@/assets/icons/spinner.svg" /></div></div>
    <div v-else>{{local(today)}} <!-- a @click="blockDay(day,activeTimetable[Object.keys(activeTimetable)[0]],coach)"><img class="icon" src="@/assets/icons/block.svg" /></a --></div>
    </div>
    <div class="add">
      <!-- book(time.hour, today, time.booking, time.confirmed, time.coach, time.color, time.title) -->
      <div class="blank" @click="book(null, today, null, false)">
      <div class="hour"><img class="icon" src="@/assets/icons/plus.svg" /></div>
      </div>
    </div>
    <div :class="(current?'current-day timetable':'timetable')+(first?' first':'')">
      <div v-for="time in activeTimetable" v-bind:key="time.hour" :class="time.tranche">
        <div :class="time.past?'blank past':(time.hour==selected?'blank selected':'blank')" @click="book(time.hour, today, time.booking, time.confirmed, time.coach, time.color, time.title, time.note, time.floor, time.group, time.user2, time.userComment, time.managerComment, time.tlength, time.userMark, time.user2Mark, time.created)">
        <div v-if="time.hour" class="hour">{{time.hour}}</div>
        <div v-if="time.title" class="title">{{time.title}}</div>
        <div v-if="typeof time.booked != 'undefined' && typeof time.booked[coach]!=  'undefined' && typeof time.booked[coach].displayName != 'undefined'" class="name">{{time.booked[coach].displayName}}</div>
        <div v-if="time.booking && typeof Users[time.booking] != 'undefined'" class="name">
          <span class="first-name">{{(Users[time.booking].displayName + " ∅").split(" ")[0]}} {{(Users[time.booking].displayName + " ∅").split(" ")[1].substr(0,1)}}. </span>
          <span class="user-credits">
            <span v-if="time.booking" :class="Users[time.user].credits<2?'cred red':'cred'">{{Users[time.user].credits}}</span>
            <span v-if="time.booking && Users[time.user].creditsD" :class="Users[time.user].creditsD<2?'cred red':'cred'">{{Users[time.user].creditsD}}</span>
            <span class="cred" v-else>Ø</span>
          </span>
        </div>
        <div class="name" v-if="time.user2 && typeof Users[time.user2] != 'undefined'">
        & <span class="first-name">{{(Users[time.user2].displayName + " ∅").split(" ")[0]}} {{(Users[time.user2].displayName + " ∅").split(" ")[1].substr(0,1)}}.</span>
          <span class="user-credits user2-credits">
            <span v-if="time.user2" :class="Users[time.user].credits<2?'cred red':'cred'">{{Users[time.user2].credits}}</span>
            <span v-if="time.user2 && Users[time.user].creditsD" :class="Users[time.user].creditsD<2?'cred red':'cred'">{{Users[time.user2].creditsD}}</span>
            <span class="cred" v-else>Ø</span>
          </span>
        </div>
        <div v-if="time.floor" class="floor"><span v-if="time.floor==1" class="down"></span><span v-if="time.floor==3" class="middle"></span><span v-if="time.floor==2" class="up"></span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {  mapGetters, mapState } from "vuex";

export default {
 // eslint-disable-next-line
 props: ['day', 'first', 'user', 'coach', 'memberBooking', 'isTomorrow'],
  data() {
    return {
      today: null, /* new Date().toISOString().slice(0, 10), */
      current: false,
      start: "07:00",
      stop: "20:30",
      selected: null,
      tlength: 60,
      isUser: false,
      timetable: {},
      isLoading: false
    };
  },
  computed: {

    ...mapGetters(['isUserAuth', 'isAdmin', 'isManager', 'getCurrentUser', 'getServerTime']),
    ...mapState({mapBookings : 'bookings'}),
    Dw() {
      const d = new Date(this.today);
      const w = d.getDay();
      return w;
    },
    Bookings() {
      return this.$store.state.bookings;
    },
    Users() {
      let test = {};

      if(this.$store.state.usersIndex!=null && Object.keys(this.$store.state.usersIndex).length)
        return this.$store.state.usersIndex;
      else
        return test;
    },
    hoursCount() {
    let count = 0;
      if(typeof this.activeTimetable !="undefined") {

      for(let i in this.activeTimetable) {
        if(this.activeTimetable[i].hour.toString().length>5) {
          if(typeof this.activeTimetable[i].tlength != "undefined")
          count = count + parseInt(this.activeTimetable[i].tlength)/60;
        }
      }
      }
      return count;
    },
    ConfigTimetable() {
      let test = {
      1 : [
          {hour: "00:00"}, {hour: "00:30"}, {hour: "01:00"}, {hour: "01:30"}, {hour: "02:00"}, {hour: "02:30"},
          {hour: "03:00"}, {hour: "03:30"}, {hour: "04:00"}, {hour: "04:30"}, {hour: "05:00"}, {hour: "05:30"},
          {hour: "06:00"}, {hour: "06:30"}, {hour: "07:00"}, {hour: "07:30"}, {hour: "08:00"}, {hour: "08:30"},
          {hour: "09:00"}, {hour: "09:30"}, {hour: "10:00"}, {hour: "10:30"}, {hour: "11:00"}, {hour: "11:30"},
          {hour: "12:00"}, {hour: "12:30"}, {hour: "13:00"}, {hour: "13:30"}, {hour: "14:00"}, {hour: "14:30"},
          {hour: "15:00"}, {hour: "15:30"}, {hour: "16:00"}, {hour: "16:30"}, {hour: "17:00"}, {hour: "17:30"},
          {hour: "18:00"}, {hour: "18:30"}, {hour: "19:00"}, {hour: "19:30"}, {hour: "20:00"}, {hour: "20:30"},
          {hour: "21:00"}, {hour: "21:30"}, {hour: "22:00"}, {hour: "22:30"}, {hour: "23:00"}, {hour: "23:30"}
      ],
      2 : [
          {hour: "00:00"}, {hour: "00:30"}, {hour: "01:00"}, {hour: "01:30"}, {hour: "02:00"}, {hour: "02:30"},
          {hour: "03:00"}, {hour: "03:30"}, {hour: "04:00"}, {hour: "04:30"}, {hour: "05:00"}, {hour: "05:30"},
          {hour: "06:00"}, {hour: "06:30"}, {hour: "07:00"}, {hour: "07:30"}, {hour: "08:00"}, {hour: "08:30"},
          {hour: "09:00"}, {hour: "09:30"}, {hour: "10:00"}, {hour: "10:30"}, {hour: "11:00"}, {hour: "11:30"},
          {hour: "12:00"}, {hour: "12:30"}, {hour: "13:00"}, {hour: "13:30"}, {hour: "14:00"}, {hour: "14:30"},
          {hour: "15:00"}, {hour: "15:30"}, {hour: "16:00"}, {hour: "16:30"}, {hour: "17:00"}, {hour: "17:30"},
          {hour: "18:00"}, {hour: "18:30"}, {hour: "19:00"}, {hour: "19:30"}, {hour: "20:00"}, {hour: "20:30"},
          {hour: "21:00"}, {hour: "21:30"}, {hour: "22:00"}, {hour: "22:30"}, {hour: "23:00"}, {hour: "23:30"}
      ],
      3 : [
          {hour: "00:00"}, {hour: "00:30"}, {hour: "01:00"}, {hour: "01:30"}, {hour: "02:00"}, {hour: "02:30"},
          {hour: "03:00"}, {hour: "03:30"}, {hour: "04:00"}, {hour: "04:30"}, {hour: "05:00"}, {hour: "05:30"},
          {hour: "06:00"}, {hour: "06:30"}, {hour: "07:00"}, {hour: "07:30"}, {hour: "08:00"}, {hour: "08:30"},
          {hour: "09:00"}, {hour: "09:30"}, {hour: "10:00"}, {hour: "10:30"}, {hour: "11:00"}, {hour: "11:30"},
          {hour: "12:00"}, {hour: "12:30"}, {hour: "13:00"}, {hour: "13:30"}, {hour: "14:00"}, {hour: "14:30"},
          {hour: "15:00"}, {hour: "15:30"}, {hour: "16:00"}, {hour: "16:30"}, {hour: "17:00"}, {hour: "17:30"},
          {hour: "18:00"}, {hour: "18:30"}, {hour: "19:00"}, {hour: "19:30"}, {hour: "20:00"}, {hour: "20:30"},
          {hour: "21:00"}, {hour: "21:30"}, {hour: "22:00"}, {hour: "22:30"}, {hour: "23:00"}, {hour: "23:30"}
      ],
      4 : [
          {hour: "00:00"}, {hour: "00:30"}, {hour: "01:00"}, {hour: "01:30"}, {hour: "02:00"}, {hour: "02:30"},
          {hour: "03:00"}, {hour: "03:30"}, {hour: "04:00"}, {hour: "04:30"}, {hour: "05:00"}, {hour: "05:30"},
          {hour: "06:00"}, {hour: "06:30"}, {hour: "07:00"}, {hour: "07:30"}, {hour: "08:00"}, {hour: "08:30"},
          {hour: "09:00"}, {hour: "09:30"}, {hour: "10:00"}, {hour: "10:30"}, {hour: "11:00"}, {hour: "11:30"},
          {hour: "12:00"}, {hour: "12:30"}, {hour: "13:00"}, {hour: "13:30"}, {hour: "14:00"}, {hour: "14:30"},
          {hour: "15:00"}, {hour: "15:30"}, {hour: "16:00"}, {hour: "16:30"}, {hour: "17:00"}, {hour: "17:30"},
          {hour: "18:00"}, {hour: "18:30"}, {hour: "19:00"}, {hour: "19:30"}, {hour: "20:00"}, {hour: "20:30"},
          {hour: "21:00"}, {hour: "21:30"}, {hour: "22:00"}, {hour: "22:30"}, {hour: "23:00"}, {hour: "23:30"}
      ],
      5 : [
          {hour: "00:00"}, {hour: "00:30"}, {hour: "01:00"}, {hour: "01:30"}, {hour: "02:00"}, {hour: "02:30"},
          {hour: "03:00"}, {hour: "03:30"}, {hour: "04:00"}, {hour: "04:30"}, {hour: "05:00"}, {hour: "05:30"},
          {hour: "06:00"}, {hour: "06:30"}, {hour: "07:00"}, {hour: "07:30"}, {hour: "08:00"}, {hour: "08:30"},
          {hour: "09:00"}, {hour: "09:30"}, {hour: "10:00"}, {hour: "10:30"}, {hour: "11:00"}, {hour: "11:30"},
          {hour: "12:00"}, {hour: "12:30"}, {hour: "13:00"}, {hour: "13:30"}, {hour: "14:00"}, {hour: "14:30"},
          {hour: "15:00"}, {hour: "15:30"}, {hour: "16:00"}, {hour: "16:30"}, {hour: "17:00"}, {hour: "17:30"},
          {hour: "18:00"}, {hour: "18:30"}, {hour: "19:00"}, {hour: "19:30"}, {hour: "20:00"}, {hour: "20:30"},
          {hour: "21:00"}, {hour: "21:30"}, {hour: "22:00"}, {hour: "22:30"}, {hour: "23:00"}, {hour: "23:30"}
      ],
      6: [
          {hour: "00:00"}, {hour: "00:30"}, {hour: "01:00"}, {hour: "01:30"}, {hour: "02:00"}, {hour: "02:30"},
          {hour: "03:00"}, {hour: "03:30"}, {hour: "04:00"}, {hour: "04:30"}, {hour: "05:00"}, {hour: "05:30"},
          {hour: "06:00"}, {hour: "06:30"}, {hour: "07:00"}, {hour: "07:30"}, {hour: "08:00"}, {hour: "08:30"},
          {hour: "09:00"}, {hour: "09:30"}, {hour: "10:00"}, {hour: "10:30"}, {hour: "11:00"}, {hour: "11:30"},
          {hour: "12:00"}, {hour: "12:30"}, {hour: "13:00"}, {hour: "13:30"}, {hour: "14:00"}, {hour: "14:30"},
          {hour: "15:00"}, {hour: "15:30"}, {hour: "16:00"}, {hour: "16:30"}, {hour: "17:00"}, {hour: "17:30"},
          {hour: "18:00"}, {hour: "18:30"}, {hour: "19:00"}, {hour: "19:30"}, {hour: "20:00"}, {hour: "20:30"},
          {hour: "21:00"}, {hour: "21:30"}, {hour: "22:00"}, {hour: "22:30"}, {hour: "23:00"}, {hour: "23:30"}
      ],
      0 : [
          /* Free sunday */
      ]
      };

      if(this.$store.state.timetable != null && typeof this.$store.state.timetable != "undefined" && Object.keys(this.$store.state.timetable).length) {
        return this.$store.state.timetable;
        }
      else
        return test;
    },
    activeTimetable() {
      let result = [];

      /* opening and closing hours */
      const timetable = JSON.parse(JSON.stringify(this.ConfigTimetable));
      let start = this.start;
      let current = start;
      const stop = this.stop;

      const dday = new Date(this.today+'T12:00:00').getDay();


      let activeBookingDay = {};
      const day = this.today;

      if( Object.keys(  this.$store.state.bookings).length ) {
      let bookings = null;
      bookings =  this.Bookings;
      Object.keys( bookings ).forEach(key => {
        if(typeof day == 'string' &&  bookings[key].id==day) {
          activeBookingDay = bookings[key];
        }
      });
      }


      /* var today = new Date(); */
      var today = this.getServerTime;

      if(this.today == today.toISOString().slice(0, 10) || this.isTomorrow) {

        let localDate = new Date(this.getServerTime);
        const hour = localDate.toLocaleString('en-US', {timeZone: 'Europe/Paris',hour: 'numeric', hour12: false}) + ':' + today.getMinutes();
        if(today.toISOString().slice(0, 10).replace(/\D/g,'')>start.replace(/\D/g,'')) {
          if(hour.slice(-2)>30)
            current = hour.slice(0,2) + ':' + '30';
          else
            current = hour.slice(0,2) + ':' + '00';
        }


      }

      if(new Date(this.today).toISOString().slice(0, 10).replace(/\D/g,'')<today.toISOString().slice(0, 10).replace(/\D/g,'') && !this.isTomorrow)
        current = stop;

      const tlength = this.tlength;
      let ending = false;
      let rbooked = false;

      Object.keys(timetable[dday]).forEach(key => {

        let blength = tlength;

        timetable[dday][key].tranche = "tranche";

       if(!this.isAdmin && !this.isManager) {
          timetable[dday][key].tranche = "selection";
       }

        if(timetable[dday][key].booked===true||(typeof timetable[dday][key].booked!="undefined" && timetable[dday][key].booked[this.coach]===true))
          timetable[dday][key].tranche = " unavailable";

        if(typeof activeBookingDay[timetable[dday][key].hour.slice(0, 5)] !="undefined"
        && typeof activeBookingDay[timetable[dday][key].hour.slice(0, 5)][this.coach] != "undefined"
        && typeof activeBookingDay[timetable[dday][key].hour.slice(0, 5)][this.coach].tlength != "undefined"
        ) {
         blength = parseInt(activeBookingDay[timetable[dday][key].hour.slice(0, 5)][this.coach].tlength)/60;

         if(!Number.isInteger(blength)) {blength = blength - 0.1;}
         timetable[dday][key].tranche = timetable[dday][key].tranche + " l"+activeBookingDay[timetable[dday][key].hour.slice(0, 5)][this.coach].tlength;
        }

        // Vérification des la plage quotidienne
        let check = true;
        if(timetable[dday][key].hour.replace(/\D/g,'')<start.replace(/\D/g,'')) check = false;
        if(timetable[dday][key].hour.replace(/\D/g,'')>=stop.replace(/\D/g,'')) check = false;
        if(ending && (ending.toString().replace(/\D/g,'')<=timetable[dday][key].hour.replace(/\D/g,''))) {
          ending = false;
        }


        if(check && !ending) {
            if(timetable[dday][key].hour.replace(/\D/g,'')<current.replace(/\D/g,''))  {
              timetable[dday][key].past = true;
              if(!this.isAdmin && !this.isManager) timetable[dday][key].tranche = "booked";
            }
            rbooked = false;

            if(typeof timetable[dday][key].booked != "undefined") {
              for(let coach in timetable[dday][key].booked) {
                if(coach==this.coach && typeof timetable[dday][key].booked[coach]!="undefined") {
                  let details = timetable[dday][key].booked[coach];
                  if(details !== false && details !== true) {
                    rbooked = true;

                    timetable[dday][key].color = "white";
                    timetable[dday][key].coach = coach;
                    if(details != null && typeof details.id !="undefined") {
                      timetable[dday][key].user = details.id;
                      timetable[dday][key].booking = details.id;
                    }
                    timetable[dday][key].confirmed = true;


                    const bookingend = parseInt(timetable[dday][key].hour.slice(0,2))+parseInt(blength);

                    if(timetable[dday][key].confirmed==true || ((timetable[dday][key].user!=null && timetable[dday][key].user!="") && timetable[dday][key].confirmed==false)) {
                      ending = ('0'+bookingend).slice(-2) + ':' + timetable[dday][key].hour.slice(-2);
                      if(typeof timetable[dday][key].color != "undefined" && timetable[dday][key].color != "white")
                      timetable[dday][key].hour = timetable[dday][key].hour.slice(0,5) + ' - ' + ending;
                    }

                  }
                }
              }
            }

            if(!rbooked && typeof activeBookingDay[timetable[dday][key].hour.slice(0, 5)] != "undefined") {

              //in bookings
              if(typeof activeBookingDay[timetable[dday][key].hour.slice(0, 5)][this.coach] != "undefined" && (!this.isUser || this.isUser == activeBookingDay[timetable[dday][key].hour.slice(0, 5)][this.coach].user)) {

                //loop des bookings
                const booking = activeBookingDay[timetable[dday][key].hour.slice(0, 5)][this.coach];



                timetable[dday][key].user = booking.user;
                timetable[dday][key].booking = booking.user;
                if(typeof booking.color != "undefined") {
                  timetable[dday][key].color = booking.color;
                  if(typeof timetable[dday][key].color != "undefined" && timetable[dday][key].color != null) {
                    timetable[dday][key].tranche = timetable[dday][key].tranche + ' color-' + timetable[dday][key].color;
                    }
                }

                if(typeof booking.floor != "undefined") timetable[dday][key].floor = booking.floor;
                if(typeof booking.group != "undefined") timetable[dday][key].group = booking.group;
                if(typeof booking.created != "undefined") timetable[dday][key].created = booking.created;
                if(typeof booking.userMark != "undefined") timetable[dday][key].userMark = booking.userMark;
                if(typeof booking.user2Mark != "undefined") timetable[dday][key].user2Mark = booking.user2Mark;
                if(typeof booking.title != "undefined") timetable[dday][key].title = booking.title;
                if(typeof booking.user2 != "undefined") timetable[dday][key].user2 = booking.user2;
                if(typeof booking.tlength != "undefined") timetable[dday][key].tlength = booking.tlength;
                if(typeof booking.userComment != "undefined") timetable[dday][key].userComment = booking.userComment;
                if(typeof booking.managerComment != "undefined") timetable[dday][key].managerComment = booking.managerComment;
                if(typeof booking.note != "undefined") timetable[dday][key].note = booking.note;
                if(typeof booking.confirmed != "undefined") timetable[dday][key].confirmed = booking.confirmed;
                if(timetable[dday][key].user==null && timetable[dday][key].confirmed==true) {
                  timetable[dday][key].tranche = timetable[dday][key].tranche + ' blocked';
                  timetable[dday][key].recurring = true;
                }
                else
                  timetable[dday][key].recurring = false
                timetable[dday][key].coach = activeBookingDay[timetable[dday][key].hour.slice(0,5)].coach;

                let end = parseInt(timetable[dday][key].hour.slice(0,2))+parseInt(blength);


                if(timetable[dday][key].confirmed==true
                || ((timetable[dday][key].user!=null
                && timetable[dday][key].user!="")
                && timetable[dday][key].confirmed==false)) {

                  if(timetable[dday][key].confirmed === false)  timetable[dday][key].tranche = timetable[dday][key].tranche + ' request';

                  timetable[dday][key].tranche = timetable[dday][key].tranche + " booked";

                  ending = ('0'+end).slice(-2) + ':' + timetable[dday][key].hour.slice(-2);

                  if(!Number.isInteger(blength)) {
                  //noempty
                  }
                  if(!Number.isInteger(blength) && timetable[dday][key].hour.slice(-2)=='30') {
                  ending = ('0'+ ++end).slice(-2) + ':' + '00' + '';

                  }
                  if(!Number.isInteger(blength) && timetable[dday][key].hour.slice(-2)=='00') {
                  ending = ('0'+end).slice(-2) + ':' + '30' + '';

                  }

                  if(typeof timetable[dday][key].color != "undefined" && timetable[dday][key].color != "white")
                  timetable[dday][key].hour = timetable[dday][key].hour.slice(0,5) + ' - ' + ending;

                } else {
                  if(typeof booking.time != "undefined") timetable[dday][key].hour = booking.time;
                }
              }
            }
            result.push(timetable[dday][key]);
        }
      });

      const rev = result.reverse();

      let booked = false;
      for(let k in rev) {
        if(rev[k].hour.length>5) {
          booked = true;
        } else {

          if(booked) {
            /* rev[k].booked = true; */
            rev[k].tranche = rev[k].tranche + ' unavailable';
          }

          booked = false;
        }
      }


      return rev.reverse();

    }
  },
   // eslint-disable-next-line
  mounted () {


    setTimeout(()=>{
      this.$emit('count', this.hoursCount);
    }, 1000);

  },
  methods: {

    blockDay(date,day,coach) {
    if(confirm("Voulez procéder au blocage de la semaine ? Cela effacera tous les rendez-vous de la journée."))
      this.book(day.hour.substr(0,5), date, null, true, coach, 'dark','Journée bloquée',null,null,null,null,null,null,840);
    },
     // eslint-disable-next-line
     book(hour, day, booking, confirmed, coach, color, title, note, floor, group, user2, userComment, managerComment, tlength, userMark, user2Mark, created) {
      this.selected = hour;
      if(typeof coach == "undefined" && typeof this.coach != "undefined") coach = this.coach
       this.$emit('book', {hour: hour, day: day, add: false, booking: booking, confirmed: confirmed, coach: coach, note: note, color: color, title: title, floor: floor, group: group, user2: user2, userComment: userComment, managerComment: managerComment, tlength: tlength, userMark: userMark, user2Mark: user2Mark, created: created})
     },
    dayOfTheWeek(d) {
      const w = {1:'Lundi', 2:'Mardi', 3:'Mercredi', 4:'Jeudi', 5:'Vendredi', 6:'Samedi', 0:'Dimanche'}
      return w[d];
    },
    getUserName(uid) {
      const users = this.Users;
      let userName = "";
      let credits = 0;
      if(typeof users[uid] != "undefined") {
        userName = users[uid].displayName;
        credits = users[uid].credits;
      }
      if(typeof users[uid] != "undefined")
      return userName + " [" + credits + "]";
    },
    local(day) {
      const d = new Date(day+'T12:00:00');
      const options = { weekday: 'short', month: 'numeric', day: 'numeric' };
      return d.toLocaleDateString('fr-FR', options);
    },
     // eslint-disable-next-line

    },
    created() {

      this.today = this.getServerTime;

      if (typeof this.day != "undefined")
        this.today = this.day

      if (typeof this.user != "undefined")
        this.isUser = this.user

      /* var totoday = new Date(); */
      var totoday = this.getServerTime;
      if(this.day == totoday.toISOString().slice(0, 10)) {
        this.current = true;
      }

      this.isLoading = true;

      setTimeout(()=>{
        this.isLoading = false;
      }, 4000);

    }

};
</script>
