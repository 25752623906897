<template>
  <div>
    <div class="loading">
      <img alt="Vue logo" src="./assets/l-endroit.svg">
    </div>
    <div v-if="isUserAuth"  :class="isAdmin||isManager?'admin-interface':'user-interface'">
      <div v-if="isAdmin||isManager">
        <div id="notifications" :class="hidden?'hidden':''" @click="toggleHidden()" v-if="someNotifications">
          <div>
            <ul>
              <li v-for="notification, ntime in Notifications" :key="ntime" class="notification">
                <a :href="notification.link" v-if="typeof notification.link != 'undefined'">&nbsp;</a>
                <span>{{getDateTime(ntime)}}</span>&nbsp;<span v-if="typeof notification.text != 'undefined'">{{notification.text}}</span>
                <span>{{getUserName(notification.uid)}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="nav">
        <div v-if="isAdmin||isManager">
          <router-link to="/" >Calendrier</router-link>
          <router-link to="/users">Membres</router-link>
          <router-link to="/logs">Historique</router-link>
          <!-- router-link v-if="isAdmin" to="/calendar" >Règlages</router-link -->
        </div><div v-else>
          <router-link to="/" ><img class="icon logo" src="@/assets/l-endroit_.svg" /></router-link>
          <!-- router-link :to="'/trainings/'+getCurrentUser.uid">Entraînements</router-link -->
          <!-- router-link :to="'/measurements/'+getCurrentUser.uid">Performances</router-link -->
        </div>
        <div>
          <!-- router-link to="/profile" v-if="!!this.$store.state.lookedAtUser">{{this.$store.state.lookedAtUser.displayName}} <span v-if="isAdmin">(Admin)</span>&nbsp;<img v-if="!isAdmin && !isManager" class="icon" src="@/assets/icons/avatar_.svg" /></router-link -->
          <router-link to="/profile">Profil <span v-if="isAdmin">(Admin)</span></router-link>&nbsp;
          <span v-if="isAdmin||isManager">
            <span v-if="!someNotifications" class="notifications-tap"><img class="icon" src="@/assets/icons/notifications.svg" /></span>
            <a v-else class="notifications-tap litup" @click.prevent="toggleHidden()"><img class="icon" src="@/assets/icons/notifications_.svg" /></a>
          </span>
        </div>
      </div>
      <router-view v-slot="{ Component }">
        <transition :name="transitionName">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <div v-else>
        <div v-if="timer()"  style="
        display: flex;
        justify-content: center;
        min-height: 100vh;
        align-items: center;
        max-width: 28em;
        margin: 0 auto;">
        Maintenance. Nous serons de retour dans quelques heures. Merci de votre patience.
        </div>
        <SignIn v-else/>
    </div>
    <footer>
      v1.4.5.2
    </footer>
  </div>
</template>

<script>
import SignIn from '@/components/SignIn.vue'; // @ is an alias to /src
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    SignIn
  },
  computed: {
    ...mapGetters(['isUserAuth', 'isAdmin', 'isManager', 'getCurrentUser', 'someNotifications', 'getServerTime']),
    Notifications(){

      const ordered = Object.keys(this.$store.state.notifications).sort().reduce(
        (obj, key) => {
          obj[key] = this.$store.state.notifications[key];
          return obj;
        },
        {}
      );

      return ordered;
    }
  },
  data() {
    return {
      hidden: true,
      transitionName : 'slide-right'
    }
  },
  watch: {
    $route(to, from) {
      console.log('route transition');
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    },
  },
  created() {
    this.$store.dispatch("bindConfig");
    if(this.isAdmin) this.$store.dispatch("initMessagingAction");
  },
  mounted() {
    this.authAction();
    setTimeout(()=>{
      document.querySelector('#app').classList.add('mounted');
  }, 1200);
    /* setTimeout(()=>{ this.toggleHidden(); }, 2200); */
  },
  methods: {
    ...mapActions(["authAction", "signOutAction"]),
    signOut() {
      this.signOutAction();
    },
    getUserName(uid) {
      let res = null;
      if(this.$store.state.usersIndex!=null)
        if(typeof this.$store.state.usersIndex[uid] != "undefined" && this.$store.state.usersIndex[uid] != null)
          if(typeof this.$store.state.usersIndex[uid].displayName != "undefined" && this.$store.state.usersIndex[uid].displayName != null)
            res = this.$store.state.usersIndex[uid].displayName;
      return res;
    },
    timer(){
      const now = this.getServerTime;
      const then = new Date('2022-09-07');
      then.setHours(1);
      return then > now;
    },
    toggleHidden() {
      if(this.hidden == false && (this.isAdmin || this.isManager)) {
        const usertmp = {};
        usertmp.user = this.getCurrentUser.uid
        this.$store.dispatch("deleteNotifications", usertmp);
        this.$store.state.notification = null;
      }
      this.hidden = !this.hidden;

    },
    getDateTime(unix_timestamp) {

      const date = typeof unix_timestamp === 'object' ? unix_timestamp : new Date(parseInt(unix_timestamp));
      /* const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000 */
      const today = this.getServerTime;
      /* const yesterday = new Date(today - DAY_IN_MS);*/
      const seconds = Math.round((today - date) / 1000);
      const minutes = Math.round(seconds / 60);
      /* const isToday = today.toDateString() === date.toDateString();
      const isYesterday = yesterday.toDateString() === date.toDateString();
      const isThisYear = today.getFullYear() === date.getFullYear(); */


      if (seconds < 5) {
        return '1m';
      } else if (seconds < 60) {
        return `${ seconds }s`;
      } else if (seconds < 90) {
        return 'une minute';
      } else if (minutes < 60) {
        return `${ minutes }m`;
      } else if (minutes < 1440) {
        return parseInt(minutes/60) + `h`;
      } else if (minutes < 2880) {
        return `hier`;
      } else {
        return parseInt(minutes/60/24) + `j`;
      }
      /*
      else {

      // Create a new JavaScript Date object based on the timestamp
      // multiplied by 1000 so that the argument is in milliseconds, not seconds.
      // Hours part from the timestamp
      var hours = date.getHours();
      // Minutes part from the timestamp
      var mminutes = "0" + date.getMinutes();
      // Seconds part from the timestamp
      var sseconds = "0" + date.getSeconds();

      // Will display time in 10:30:23 format
      return hours + ':' + mminutes.substr(-2) + ':' + sseconds.substr(-2);


      } */

    },
  }
};

/* <span v-if="getCurrentUser">{{getCurrentUser().displayName}}</span><span v-else>Profile<span> */
</script>

<style lang="scss">
  @import './assets/normalize.css';
  @import './assets/fontello.css';
  @import './assets/fnt.css';
  @import './assets/app.scss';
</style>
